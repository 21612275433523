<template>
  <div class="app-container page">
    <div class="page-project" v-loading="proLoading">
      <div class="project-title">项目列表</div>
      <div v-if="projectList.length > 0">
        <div
          :class="proIndex == index ? 'bg-list' : 'project-list'"
          v-for="(item, index) in projectList"
          :key="index"
          @click="changeProject(index)"
        >
          <div>{{ item.label }}</div>
        </div>
      </div>
      <div v-else style="margin-top: 28px">{{ projectText }}</div>
    </div>
    <div class="page-content">
      <div class="filter-line">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="addTemplate('add')"
          >新建模版</el-button
        >
      </div>

      <div v-loading="tempLoading" class="border-loading">
        <div class="template-border" v-if="queryByTemplateData.length > 0">
          <div
            class="template-list"
            v-for="(itProject, index) in queryByTemplateData"
            :key="index"
          >
            <div class="template-name">{{ itProject.templateName }}</div>
            <div class="content">
              <div class="timeCh">创建时间：</div>
              <div class="timeBy">{{ itProject.createdTimeStr }}</div>
            </div>
            <div class="opera">
              <!-- <span>复制</span> -->
              <span @click="addTemplate('edit', itProject.id)">修改</span>
              <span class="grey" @click="delTableTemplate(itProject.id)"
                >删除</span
              >
            </div>
          </div>
        </div>
        <div v-else style="margin-top: 32px">{{ promptText }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getStoreList,
  queryTemplateByStore,
  delTemplate,
} from "@/api/ruge/gsPark/customerService/projectTemplate";
export default {
  name: "BMSparkProjectTemplate",
  data() {
    return {
      projectList: [],
      proIndex: 0,
      queryByTemplateData: [],
      proLoading: false,
      tempLoading: false,
      promptText: "",
      projectText: "",
    };
  },
  created() {
    this.getTabStoreList();
  },
  methods: {
    delTableTemplate(templateId) {
      this.$confirm("此操作将永久删除该模版，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delTemplate({
            templateId: templateId,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success("删除成功！");
              this.getQueryTemplate();
            } else {
              this.$message.warning("删除失败！");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    addTemplate(type, id) {
      const tId = id ? id : "";
      let params = {
        path: "/gsPark/customerRelaManagement/projectTemplate/detail",
        query: { viewStatus: type, templateId: tId },
      };
      this.$router.push(params);
    },
    getTabStoreList() {
      this.proLoading = true;
      getStoreList()
        .then((res) => {
          if (res.length > 0) {
            this.projectList = res.map((item) => {
              return {
                value: item.storeId,
                label: item.storeName,
              };
            });
            this.getQueryTemplate();
          } else {
            this.projectText = "暂无项目列表";
            this.projectList = [];
          }
        })
        .catch(() => {
          this.projectList = [];
        })
        .finally(() => {
          this.proLoading = false;
        });
    },
    getQueryTemplate() {
      this.tempLoading = true;
      queryTemplateByStore({
        storeId: this.projectList[this.proIndex].value,
      })
        .then((res) => {
          if (res.length > 0) {
            this.queryByTemplateData = res;
          } else {
            this.promptText = "该项目暂无模版";
            this.queryByTemplateData = [];
          }
        })
        .catch(() => {
          this.queryByTemplateData = [];
        })
        .finally(() => {
          this.tempLoading = false;
        });
    },
    changeProject(index) {
      this.proIndex = index;
      this.getQueryTemplate();
    },
  },
};
</script>
<style lang="less"  scoped>
.page {
  display: flex;
  .page-project {
    width: 250px;
    .project-title {
      height: 40px;
      line-height: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #f8f8f8;
      font-weight: 500;
      font-size: 16px;
      color: #2f3941;
    }
    .project-list {
      padding-left: 32px;
      margin-top: 10px;
      width: 236px;
      height: 44px;
      border-radius: 2px 2px 2px 2px;
      font-weight: 400;
      font-size: 14px;
      color: #2f3941;
      line-height: 44px;
      cursor: pointer;
    }
    .bg-list {
      padding-left: 32px;
      margin-top: 10px;
      width: 236px;
      height: 44px;
      line-height: 44px;
      background: #f5f7fa;
      border-radius: 2px 2px 2px 2px;
      font-weight: 500;
      font-size: 14px;
      color: #4374fa;
      cursor: pointer;
    }
  }
  .page-content {
    margin-left: 32px;
    width: 100%;
    .border-loading {
      width: 100%;
      .template-border {
        margin-top: 32px;
        .template-list {
          display: inline-block;
          margin-right: 24px;
          margin-bottom: 24px;
          width: 270px;
          height: 173px;
          background: #ffffff;
          box-shadow: 0px 4px 11px 0px rgba(175, 180, 207, 0.26);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #dcdfe5;
          .template-name {
            padding-left: 20px;
            height: 48px;
            line-height: 48px;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            border-bottom: 1px solid #dcdfe5;
            overflow: hidden; // 超出的文本隐藏

            text-overflow: ellipsis; // 溢出用省略号显示

            white-space: nowrap; // 溢出不换行
          }
          .content {
            height: 66px;
            border-bottom: 1px solid #dcdfe5;
            padding-left: 20px;
            .timeCh {
              margin-top: 16px;
              font-weight: 400;
              font-size: 14px;
              color: #909399;
              line-height: 20px;
              height: 20px;
            }
            .timeBy {
              margin-top: 10px;
              line-height: 20px;
              height: 20px;
              font-weight: 400;
              font-size: 14px;
              color: #909399;
            }
          }
          .opera {
            margin-left: 20px;
            margin-top: 12px;
            font-weight: 400;
            font-size: 14px;
            color: #4374fa;
            span {
              margin-right: 24px;
              cursor: pointer;
            }
            .grey {
              color: #909399;
            }
          }
        }
      }
    }
  }
}
</style>