import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 房源项目模板 - 获取房源项目下拉选项集合
 * @param params
 */
export function getStoreList(params) {
  return request({
    url: envInfo.bgApp.crmWebPath + '/roomData/getStoreList',
    method: 'get',
    params,
  });
}

/**
 * 房源项目模板 - 根据项目id获取模板列表
 * @param params
 */
export function queryTemplateByStore(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/template/queryTemplateByStore',
    method: 'get',
    params,
  });
}

/**
 * 房源项目模板 - 新建
 * @param params
 */
export function addTemplate(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/template/addTemplate',
    method: 'post',
    data: params,
  });
}

/**
 * 房源项目模板 - 编辑模板
 * @param params
 */
export function editTemplate(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/template/editTemplate',
    method: 'post',
    data: params,
  });
}

/**
 * 房源项目模板 - 获取模板详情
 * @param params
 */
export function getTemplateDetail(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/template/getTemplateDetail',
    method: 'get',
    params,
  });
}

/**
 * 房源项目模板 - 删除模板
 * @param params
 */
export function delTemplate(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/template/delTemplate',
    method: 'get',
    params,
  });
}
