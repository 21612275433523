var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container page" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.proLoading,
            expression: "proLoading",
          },
        ],
        staticClass: "page-project",
      },
      [
        _c("div", { staticClass: "project-title" }, [_vm._v("项目列表")]),
        _vm.projectList.length > 0
          ? _c(
              "div",
              _vm._l(_vm.projectList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: _vm.proIndex == index ? "bg-list" : "project-list",
                    on: {
                      click: function ($event) {
                        return _vm.changeProject(index)
                      },
                    },
                  },
                  [_c("div", [_vm._v(_vm._s(item.label))])]
                )
              }),
              0
            )
          : _c("div", { staticStyle: { "margin-top": "28px" } }, [
              _vm._v(_vm._s(_vm.projectText)),
            ]),
      ]
    ),
    _c("div", { staticClass: "page-content" }, [
      _c(
        "div",
        { staticClass: "filter-line" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  return _vm.addTemplate("add")
                },
              },
            },
            [_vm._v("新建模版")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tempLoading,
              expression: "tempLoading",
            },
          ],
          staticClass: "border-loading",
        },
        [
          _vm.queryByTemplateData.length > 0
            ? _c(
                "div",
                { staticClass: "template-border" },
                _vm._l(_vm.queryByTemplateData, function (itProject, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "template-list" },
                    [
                      _c("div", { staticClass: "template-name" }, [
                        _vm._v(_vm._s(itProject.templateName)),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "timeCh" }, [
                          _vm._v("创建时间："),
                        ]),
                        _c("div", { staticClass: "timeBy" }, [
                          _vm._v(_vm._s(itProject.createdTimeStr)),
                        ]),
                      ]),
                      _c("div", { staticClass: "opera" }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.addTemplate("edit", itProject.id)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "grey",
                            on: {
                              click: function ($event) {
                                return _vm.delTableTemplate(itProject.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _c("div", { staticStyle: { "margin-top": "32px" } }, [
                _vm._v(_vm._s(_vm.promptText)),
              ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }